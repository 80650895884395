<template>
  <div id="app">
    <router-view></router-view>
    
  </div>
</template>
<script>
export default {

  //===========================下面是解决刷新页面丢失vuex数据
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))));
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state));
    });
  }
};
</script>
<style lang="scss">
#app {
  height: 100%;
  width: 100%;
}
//登录错误时上方弹出框
.el-message{
  min-width:100px !important;
}
.el-main {
  width: 97.5%;
  height: 91%;
  // height: 975px;
  margin: auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 0 !important;
  margin-bottom: 20px;
  opacity: 1;
  box-shadow: 0px 0px 20px 0px rgba(21, 33, 65, 0.25);
}
</style>

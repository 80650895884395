import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import {instance} from '../src/utils/request'
Vue.prototype.$axios = instance
export const eventBus = new Vue()
import '@/assets/Font/fonts.css'
import '@/flexible'
// import 'lib-flexible/flexible'
import '@/icons' // icon
Vue.use(ElementUI);
Vue.config.productionTip = false



// let echarts = require("echarts/lib/echarts");
// import './authRouter'

// // 引入饼状图组件
// require("echarts/lib/chart/pie");
// // 引入提示框和title组件，图例
// require("echarts/lib/component/tooltip");
// require("echarts/lib/component/title");
// require("echarts/lib/component/legend");
// Vue.prototype.$echarts = echarts; //引入组件
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

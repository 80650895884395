// import Vue from 'vue'
// import Vuex from 'vuex'
// import getters from './getters'
// Vue.use(Vuex)

// let moduleFn = require.context('./module', false, /\.js$/i)
// let modules = moduleFn.keys().reduce((prev, curr) => {
//   let value = { ...moduleFn(curr).default, namespaced: true }
//   // 字符串方法和正则表达式中的分组
//   let key = curr.match(/\.\/(\w+)\.js/i)[1]
//   prev[key] = value
//   return prev
// }, {})

// const store = new Vuex.Store({
//   getters,
//   modules
// })

// export default store

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    line: [],
    username: '',
    Id:'',
    type:''
  },
  mutations: {
    updateData(state, data) {
      state.line = data
    },
    usernameData(state, data) {
      state.username = data
    },
    getId(state, data) {
      state.Id = data
    },
    getType(state, data) {
      state.type = data
    }
  },
  actions: {

  },
  getters: {

  }
})

import axios from "axios";
export const instance = axios.create({
    baseURL: '/dev-api'
});
// 请求拦截器
instance.interceptors.request.use((config) => {
    config.headers.Token = localStorage.getItem('token');
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token'); // 让每个请求携带自定义token 请根据实际情况自行修改
    config.timeout = 300000;
    return config;
});
// 响应拦截器
// instance.interceptors.response.use(
//   (ret) => ret.data,
//   (err) => Promise.reject(err)
// );
export const get = (url, config = {}) => instance.get(url, config);
export const post = (url, data = {}, config = {}) =>
    instance.post(url, data, config);


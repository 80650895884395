import Vue from 'vue'
import VueRouter from "vue-router";
import store from '../store'
Vue.use(VueRouter)
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
  },
  {
    path: '/dj/login',
    name: 'loginDj',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/dj'),
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/weekly/report',
    name: 'report',
    meta:{
      // 页面标题title
      title: '处罚周报'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/weekly/xm'),
  },
  {
    path: '/weekly/report/v1',
    name: 'report',
    meta:{
      // 页面标题title
      title: '处罚周报'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/weekly/xm/v1'),
  },
  {
    path: '/weekly/report/v2',
    name: 'report',
    meta:{
      // 页面标题title
      title: '处罚周报'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/weekly/xm/v2'),
  },
  {
    path: '/weekly/dj/report',
    name: 'report',
    meta:{
      // 页面标题title
      title: '处罚周报'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/weekly/dj'),
  },
  {
    path: '/weekly/dj/report/v1',
    name: 'report',
    meta:{
      // 页面标题title
      title: '处罚周报'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/weekly/dj/v1'),
  },
  {
    path: '/weekly/dj/report/v2',
    name: 'report',
    meta:{
      // 页面标题title
      title: '处罚周报'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/weekly/dj/v2'),
  },
  {
    path: '/weekly/report/detail',
    name: 'weeklyDetail',
    meta:{
      // 页面标题title
      title: '处罚周报'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/weekly/detail'),
  },
  {
    path: '/weekly/report/bank/detail',
    name: 'weeklyBankDetail',
    meta:{
      // 页面标题title
      title: '处罚周报'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/weekly/detail/bank'),
  },
  {
    path: '/report/detail',
    name: 'reportDetail',
    meta:{
      // 页面标题title
      title: '政策报告'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/report'),
  },
  {
    path: '/header',
    resourcePermission: 'header',
    component: () => import(/* webpackChunkName: "header" */ '../views/header'),
    children: [
      {
        path: 'home',
        // path: '/header/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "dutyList" */ '../views/home'),
        meta: {
          keepAlive: true // 需要被缓存
        }
      },

      {
        path: 'dutyList',
        component: () => import(/* webpackChunkName: "dutyList" */ '../views/dutyList'),
        name: 'dutyList',
      },
      {
        path: 'dutyList/detail',
        component: () => import(/* webpackChunkName: "detail" */ '../views/dutyList/detail/index.vue'),
      },
      {
        path: 'roles',
        name: 'roles',
        component: () => import(/* webpackChunkName: "roles" */ '../views/roles'),
      },
      {
        path: 'roles/detail',
        name: 'detail',
        component: () => import(/* webpackChunkName: "roles" */ '../views/roles/detail/index.vue'),
      },
      {
        path: 'punish',
        name: 'punish',
        component: () => import(/* webpackChunkName: "punish" */ '../views/punish/senior'),
      },
      {
        path: 'punish/detail',
        component: () => import(/* webpackChunkName: "detail" */ '../views/punish/detail/index.vue'),
      },
      {
        path: 'punish/article',
        name: 'article',
        component: () => import(/* webpackChunkName: "detail" */ '../views/punish/article/index.vue'),
      },
      {
        path: 'compliance',
        name: 'compliance',
        component: () => import(/* webpackChunkName: "detail" */ '../views/compliance/index.vue'),
      },
      {
        path: 'compliance/article',
        name: 'compliance/article',
        component: () => import(/* webpackChunkName: "detail" */ '../views/compliance/detail/index.vue'),
      },
      {
        path: 'auth',
        name: 'auth',
        component: () => import( '../views/auth/index.vue'),
      },
      {
        path: 'punish/senior',
        name: 'senior',
        component: () => import(/* webpackChunkName: "detail" */ '../views/punish/senior/index.vue'),
      },
      {
        path: 'punish/charts',
        name: 'charts',
        component: () => import(/* webpackChunkName: "detail" */ '../views/punish/charts/index.vue'),
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 全置前置守卫
router.beforeEach((to, from, next) => {
  if (to.path == '/login' || to.path == '/weekly/report' || to.path == '/weekly/dj/report' || to.path == '/weekly/report/detail'
      || to.path == '/weekly/dj/report/v1'|| to.path == '/weekly/dj/report/v2' || to.path == '/weekly/report/bank/detail'
      || to.path =='/report/detail'|| to.path =='/dj/login'
      || to.path == '/weekly/report/v1'|| to.path == '/weekly/report/v2'

  ) {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title
    }
    console.log(to.path);
    next();
  }
  else {
    let token = localStorage.getItem('token');
    if (token === null || token === '') {
      next('/login');
      // next();
    } else {
      next();
    }
  }
})

//解决路由重复点击冗余
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
